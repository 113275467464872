import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageHeader from 'components/PageHeader';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-1a.png';
import image2 from 'images/project-1b.png';
import image3 from 'images/project-1c.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Long-Overdue Repairs to a Small Family Home"
      meta={[
        {
          name: 'description',
          content:
            'In a small two-bedroom family home, the trust has replaced rotten floorboards, installed a cabin and given the kitchen and bathroom a much-needed facelift.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Long-Overdue Repairs to a Small Family Home"
      intro="In a small two-bedroom family home, the trust has replaced rotten floorboards, installed a cabin and given the kitchen and bathroom a much-needed facelift."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Parents of six, with an elderly mother to care for, were feeling stressed about
            overcrowding in their small two-bedroom home and at a loose end trying to resolve the
            problem. Mum had spent years applying for back loans to carry out much-needed
            developments to the house but met with little success.
          </Paragraph>
          <Paragraph>
            &ldquo;Our home was in urgent need of repair. The kitchen floor was in such a bad state
            that one of our children fell through rotting floorboards and we discovered that the
            water cylinder had been leaking for years. Our bathroom, floor was much the same and my
            elderly mother desperately needed disabled access. As a large family living in a
            two-bedroom house, we were crying out for more space. Initially, I got quotes from
            builders to fix our home but the work was too expensive. When I heard the figures,
            I&rsquo;d just switch off because I knew we could never afford it. We spent years trying
            to &lsquo;tick the boxes&rsquo; to get a bank loan. All the banks turned us down.
            Ryhaven helped us when no one else would.&rdquo;
          </Paragraph>
          <Paragraph>
            Ryhaven have since installed a cabin in the backyard to house two teenage boys, replaced
            the rotten floors, given the kitchen and bathroom a much-needed facelift (including
            disabled access for elderly family member) and installed new cabinetry and appliances.
          </Paragraph>
          <Paragraph>
            The family worked with a financial adviser to agree on weekly repayments that fit within
            their budget; &ldquo;Ryhaven actually listened to us. They understood our financial
            situation,&rdquo; Mum adds.
          </Paragraph>
          <Paragraph>
            The family are excited with the outcome and love their new home environment. In
            particular, mum is thrilled that she can enjoy cooking again in her brand new kitchen.
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
